<template>
<div class="smart-guided-camera-container">
    <div class="smart-guided-camera-content">
        <div class="smart-guided-camera-description">
            <p class="title">Smart guided camera</p>
            <p class="description">Don't miss an angle and take quality photos of your car with our guided camera (on mobile).</p>
            <a :href="photoGuideLink.fromMyCar" target="_blank" class="act-btn">Learn more</a>
        </div>
        <div class="img-container">
            <img src="../../assets/images/smart_guided_camera.svg" alt="" />
        </div>
    </div>
</div>
</template>

<script>
import { AUTOFLIP_URL } from "@/helper/constant";
import { mapState } from "vuex";
export default {
  name: "SmartGuidedCamera",
  data() {
    return {
      autoflipUrl: AUTOFLIP_URL,
    };
  },
  computed: {
    ...mapState({
        photoGuideLink: (state) => state.commonVariables.photoGuideLink,
    })
  },
};
</script>

<style lang="scss">
.smart-guided-camera-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .smart-guided-camera-content {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        width: 397px;
        .smart-guided-camera-description {
            padding: 5px 15px 0;
            font-size: 16px;
            line-height: 24px;
            .title {
                font-weight: 600 !important;
                font-size: 48px;
                line-height: 48px;
                color: #414141;
            }
            .description {
                font-family: 'Avenir Next LT W05 Regular';
                font-size: 16px;
                line-height: 24px;
                color: #6F6F6F;
                margin-bottom: 1.5rem;
            }
            .act-btn {
                padding: 12px 30px;
                font-family: 'Avenir Next LT W05 Regular' !important;
                font-weight: 400 !important;
                color: var(--primary-color);
                background: #FFFFFF;
                border: 1px solid var(--primary-color);
                border-radius: 6px !important;
            }
        }
        .img-container {
            margin-bottom: 2rem;
            img {
                width: 358px;
            }
        }
    }
}

@media screen and(min-width: 368px) {
    .smart-guided-camera-container {
        .smart-guided-camera-content {
            .smart-guided-camera-description {
                padding: 30px 30px 0;
            }
        }
    }
}

@media screen and(min-width: 1025px) {
    .smart-guided-camera-container {
        margin: unset;
        padding: 30px 30px;
        .smart-guided-camera-content {
            width: 1148px;
            height: 100%;
            flex-direction: row !important;
            justify-content: space-between;
            align-items: center;
            .smart-guided-camera-description {
                text-align: left;
                width: 420px;
                padding: 0 0 0 30px !important;
                .title {
                    font-size: 60px;
                    line-height: 60px;
                }
            }
            .img-container {
                margin-bottom: unset;
                img {
                    width: 100%;
                }
            }
        }
    }
}
</style>