var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rego-search-content mx-auto"},[_vm._m(0),(_vm.apiNotFound & !_vm.isLoadingApi)?_c('p',{staticClass:"mb-0 api-not-found"},[_vm._v(" The email or rego entered is incorrect. Please try again. ")]):_vm._e(),_c('div',{staticClass:"position-relative mb-2 mt-3"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('b-form-input',{staticClass:"input-login",class:{
        error: _vm.$v.authenticateData.email.$error,
        'shadow-none': true,
      },attrs:{"type":"email","placeholder":"Enter email","autocomplete":"off","required":""},on:{"keyup":[function($event){return _vm.hideError($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}]},model:{value:(_vm.authenticateData.email),callback:function ($$v) {_vm.$set(_vm.authenticateData, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"authenticateData.email"}}),(
        _vm.$v.authenticateData.email.$dirty && !_vm.$v.authenticateData.email.email
      )?_c('p',{staticClass:"msg-error mb-0 d-block"},[_c('i',[_vm._v("Invalid email format")])]):_vm._e()],1),_vm._m(1),_c('div',{staticClass:"position-relative"},[_vm._m(2),_c('b-input',{staticClass:"input-login",class:{
        error: _vm.$v.authenticateData.rego.$error,
        'shadow-none': true,
      },attrs:{"placeholder":"Enter rego","autocomplete":"off","required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}},model:{value:(_vm.authenticateData.rego),callback:function ($$v) {_vm.$set(_vm.authenticateData, "rego", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"authenticateData.rego"}}),(
        _vm.$v.authenticateData.rego.$dirty && !_vm.$v.authenticateData.rego.alphaNum
      )?_c('p',{staticClass:"msg-error mb-0"},[_c('i',[_vm._v("Please enter only alphanumeric characters")])]):(
        _vm.$v.authenticateData.rego.$dirty && !_vm.$v.authenticateData.rego.maxLength
      )?_c('p',{staticClass:"msg-error mb-0"},[_c('i',[_vm._v("Please enter 1 to 9 characters!")])]):_c('p',{staticClass:"mb-3"})],1),(
      _vm.$v.authenticateData.email.$dirty &&
      !_vm.$v.authenticateData.email.required ||
      _vm.$v.authenticateData.rego.$dirty &&
      !_vm.$v.authenticateData.rego.required
    )?_c('p',{staticClass:"msg-error mb-0"},[_c('i',[_vm._v("Please enter your rego or email to proceed")])]):_vm._e(),_c('button',{ref:"searchButton",class:[
      _vm.activeBtnGo ? 'active-btn-go' : 'default-btn-go',
      'btn-go fs-16 text-white' ],on:{"click":_vm.regoSearch}},[(!_vm.isLoadingApi)?_c('b',{staticClass:"font-weight-regular"},[_vm._v("Let's go")]):_c('b-spinner',{staticClass:"btn-loading",attrs:{"label":"Loading..."}})],1),_c('div',{staticClass:"position-relative"},[_c('p',{staticClass:"mb-0 msg-api api-loading mt-3",style:(!_vm.isLoadingApi ? 'display: none' : 'display: block')},[_c('i',[_vm._v("Searching for your details")])])]),_c('div',{staticClass:"position-relative"},[_c('p',{staticClass:"mb-0 login-issue-text text-center fs-14"},[_c('a',{attrs:{"href":_vm.myCarLinks.troubleLogging,"target":"_blank"}},[_vm._v("I'm having trouble logging in")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2 title-rego-search"},[_c('div',{staticClass:"welcome-title"},[_vm._v("Welcome back!")]),_c('div',{staticClass:"welcome-content mt-2"},[_vm._v(" Login to view and update your car details and photos. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-form-divider"},[_c('span',{staticClass:"divider-line"}),_c('span',{staticClass:"font-weight-regular"},[_vm._v("OR")]),_c('span',{staticClass:"divider-line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('label',{attrs:{"for":"rego"}},[_vm._v("Rego")])])}]

export { render, staticRenderFns }