import webApi from '@/services/api'
export class TopPageService {
    authenticateSeller(data) {
        return webApi.post('lead-update-service/auth', data)
    }
    searchRego(data) {
        return webApi.post('car-detail/rego-redbook-api', data)
    }
}

const topPageService = new TopPageService()

export default topPageService
