<template>
  <div class="rego-search-content mx-auto">
    <div class="mb-2 title-rego-search">
      <div class="welcome-title">Welcome back!</div>
      <div class="welcome-content mt-2">
        Login to view and update your car details and photos.
      </div>
    </div>
    <p class="mb-0 api-not-found" v-if="apiNotFound & !isLoadingApi">
      The email or rego entered is incorrect. Please try again.
    </p>
    <div class="position-relative mb-2 mt-3">
      <label for="email">Email</label>
      <b-form-input
        class="input-login"
        type="email"
        v-model.trim="authenticateData.email"
        placeholder="Enter email"
        autocomplete="off"
        required
        @keyup="hideError($event)"
        @keyup.enter="submit"
        :class="{
          error: $v.authenticateData.email.$error,
          'shadow-none': true,
        }"
      />
      <p
        v-if="
          $v.authenticateData.email.$dirty && !$v.authenticateData.email.email
        "
        class="msg-error mb-0 d-block"
      >
        <i>Invalid email format</i>
      </p>
    </div>
    <div class="search-form-divider">
      <span class="divider-line"></span>
      <span class="font-weight-regular">OR</span>
      <span class="divider-line"></span>
    </div>
    <div class="position-relative">
      <div class="d-flex">
        <label for="rego">Rego</label>
        <!-- <CustomTooltip :text="tooltipText">
          <span class="span-icon"> 
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_7424_2961"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="1"
                y="1"
                width="11"
                height="11"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.50001 1.08334C3.51001 1.08334 1.08334 3.51001 1.08334 6.50001C1.08334 9.49001 3.51001 11.9167 6.50001 11.9167C9.49001 11.9167 11.9167 9.49001 11.9167 6.50001C11.9167 3.51001 9.49001 1.08334 6.50001 1.08334ZM6.50001 9.20834C6.20209 9.20834 5.95834 8.96459 5.95834 8.66668V6.50001C5.95834 6.20209 6.20209 5.95834 6.50001 5.95834C6.79793 5.95834 7.04168 6.20209 7.04168 6.50001V8.66668C7.04168 8.96459 6.79793 9.20834 6.50001 9.20834ZM5.95834 4.87501H7.04168V3.79168H5.95834V4.87501Z"
                  fill="black"
                />
              </mask>
              <g mask="url(#mask0_7424_2961)">
                <rect width="13" height="13" fill="#2BB673" />
              </g>
            </svg>
          </span>
        </CustomTooltip> -->
      </div>
      <b-input
        class="input-login"
        v-model.trim="authenticateData.rego"
        placeholder="Enter rego"
        autocomplete="off"
        required
        @keyup.enter="submit"
        :class="{
          error: $v.authenticateData.rego.$error,
          'shadow-none': true,
        }"
      />
      <p
        v-if="
          $v.authenticateData.rego.$dirty && !$v.authenticateData.rego.alphaNum
        "
        class="msg-error mb-0"
      >
        <i>Please enter only alphanumeric characters</i>
      </p>
      <p
        v-else-if="
          $v.authenticateData.rego.$dirty && !$v.authenticateData.rego.maxLength
        "
        class="msg-error mb-0"
      >
        <i>Please enter 1 to 9 characters!</i>
      </p>
      <p v-else class="mb-3"></p>
    </div>
     <p
      v-if="
        $v.authenticateData.email.$dirty &&
        !$v.authenticateData.email.required ||
        $v.authenticateData.rego.$dirty &&
        !$v.authenticateData.rego.required
      "
      class="msg-error mb-0"
    >
      <i>Please enter your rego or email to proceed</i>
    </p>
    <button
      @click="regoSearch"
      :class="[
        activeBtnGo ? 'active-btn-go' : 'default-btn-go',
        'btn-go fs-16 text-white',
      ]"
      ref="searchButton"
    >
      <b class="font-weight-regular" v-if="!isLoadingApi">Let's go</b>
      <b-spinner v-else label="Loading..." class="btn-loading"></b-spinner>
    </button>
    <div class="position-relative">
      <p class="mb-0 msg-api api-loading mt-3" :style="!isLoadingApi ? 'display: none' : 'display: block'">
        <i>Searching for your details</i>
      </p>
    </div>
    <div class="position-relative">
      <p class="mb-0 login-issue-text text-center fs-14">
        <a :href="myCarLinks.troubleLogging" target="_blank">I'm having trouble logging in</a>
      </p>
    </div>
  </div>
</template>

<script>
import { maxLength, alphaNum, email } from "vuelidate/lib/validators";
import topPageService from "@/services/top-page";
import {
  ACTION_AUTHENTICATE_SELLER,
  REGO_RE_LOOKUP,
  SAVE_ACTION_LOG,
} from "@/store/authenticate-seller/actions";
import { mapState } from "vuex";
import { getParamFromURL, imageObjectsFromUrls, logError } from "@/helper/utils";
import { SET_REGO_SEARCH_FLAG } from "@/store/info/actions";

export default {
  name: "RegoSearchForm",
  data() {
    return {
      authenticateData: {
        rego: null,
        email: null,
        appraisalId: '',
      },
      isLoadingApi: false,
      apiNotFound: false,
      selfServiceConfig: null,
      tooltipText: {
        1:
          "Enter the registration number that you submitted in your enquiry.",
        2:
          `If you didn't provide one, then enter "TEMPREGO".`,
      },
    };
  },
  methods: {
    hideError(event) {
      if (event?.key !== "enter") {
        this.isLoadingApi = false;
        this.apiNotFound = false;
      }
    },
    submit() {
      this.regoSearch();
    },
    async regoSearch(e) {
      this.hideError(e);
      this.$v.authenticateData.$touch();
      if (!this.$v.authenticateData.$invalid) {
        this.isLoadingApi = true;
        try {
          this.authenticateData.appraisalId = getParamFromURL(window.location.href, "appraisalId") || '';
          this.authenticateData.locid = getParamFromURL(window.location.href, "locid") || '';
          const searchResult = await topPageService.authenticateSeller(
            this.authenticateData
          );
          if (searchResult.status === 201 && searchResult.data.authenticated) {
            searchResult.data.appraisalId = this.authenticateData.appraisalId;
            searchResult.data.locid = this.authenticateData.locid;
            const {
              imageUrls,
              newImages,
              deletedImages,
              labels,
              carInfo,
              rego,
              listed,
              readonlyMode,
              leadSource,
            } = searchResult.data;
            if (imageUrls && imageUrls.length > 0) {
              this.$store.dispatch(
                "setUploadedImages",
                imageObjectsFromUrls(imageUrls, true, deletedImages)
              );
              this.$store.dispatch("setIsUseGuidedUpload", false);
            }
            if (newImages && newImages.length > 0) {
              this.$store.dispatch("setUploadedImages", [
                ...this.uploadImages,
                ...imageObjectsFromUrls(newImages, false, deletedImages),
              ]);
            }
            this.$store.dispatch(ACTION_AUTHENTICATE_SELLER, searchResult.data);
            if (
              labels.some(
                (label) => label.toLowerCase() === "rego_lookup_not_done"
              ) &&
              (carInfo.location || this.authenticateData.locid === "2")
            ) {
              if (["TEMPREGO", ""].includes(rego)) {
                this.$store.dispatch(
                  "updateIsShowRegoPopup",
                  listed || readonlyMode ? false : true
                );
              } else {
                await this.handleRegoSearch(rego, carInfo.location, leadSource);
              }
            }
            this.isLoadingApi = false;
            this.$router.push({
              name: "navigation",
              query: this.$route.query,
            });
          } else {
            this.isLoadingApi = false;
            this.apiNotFound = true;
            this.$store.dispatch(SAVE_ACTION_LOG, {
              email: this.authenticateData.email,
              rego: this.authenticateData.rego,
              appraisalId: this.authenticateData.appraisalId,
              url: window.location.href,
            });
          }
        } catch (err) {
          this.isLoadingApi = false;
          if (err.response && err.response.status === 422) {
            const errors = err.response.data.errors;
            Object.keys(errors).forEach(field => {
              this.$toast.clear();
              this.$toast.error(`${err.response.data.message}: ${errors[field][0]}`);
            });
          } else if (err.response) {
            this.$toast.clear();
            this.$toast.error(err.response.data.message);
          } else {
            logError(`Authenticate seller error: ${err}`);
          }
        }
      }
    },
     async handleRegoSearch(regoNumber, state, leadSource) {
      try {
        this.$store.dispatch(SET_REGO_SEARCH_FLAG, true);
        const seachResult = await topPageService.searchRego({
          regoNumber,
          state,
          leadSource,
          locid: getParamFromURL(window.location.href, "locid") || '',
        });
        if (seachResult.status === 201) {
          this.$store.dispatch(REGO_RE_LOOKUP, seachResult.data);
        }
      } catch (error) {
        if (error.response.status !== 404) {
          logError(`Rego search error: ${error}`);
        }
      }
    },
  },
  validations: {
    authenticateData: {
      rego: {
        required: function() {
          return !!this.authenticateData.rego || !!this.authenticateData.email;
        },
        alphaNum,
        maxLength: maxLength(9),
      },
      email: {
        required: function() {
          return !!this.authenticateData.rego || !!this.authenticateData.email;
        },
        email,
      },
    },
  },
  computed: {
    ...mapState({
      uploadImages: (state) => state.photo.uploadImage,
      myCarLinks: (state) => state.commonVariables.myCarLinks,
    }),
    activeBtnGo: function () {
      return !this.$v.authenticateData.$invalid;
    },
  },
  mounted() {
    const authenticateInfo = {
      rego: this.$store.getters.rego,
      email: this.$store.getters.email,
    };

    if (authenticateInfo.rego !== '' && authenticateInfo.email !== '') {
      this.authenticateData.rego = authenticateInfo.rego;
      this.authenticateData.email = authenticateInfo.email;
    }
  },
};
</script>

<style lang="scss">
$white: #ffffff;
$loading_color: #74bd58;
$green: #00b86d;
$error_color: #c1272d;

$item_height: 50px;

.rego-search-content {
  background-color: $white;

  .search-form-divider {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    .divider-line {
      flex: 1;
      height: 2px;
      background-color: var(--primary-color);
    }
    span:not(.divider-line) {
      color: var(--primary-color);
      padding: 0 10px;
    }
  }

  .title-rego-search {
    color: #292644;
    line-height: 24px;
  }

  .label-input {
    color: #999999;
    top: -10px;
    left: 10px;
    padding-left: 10px;
    padding-right: 14px;
    background-color: $white;
  }

  .no-rego {
    color: #b3b3b3;
    width: fit-content;
    text-decoration: underline;
    margin: 2px 0 9px auto;
  }

  .btn-go {
    width: 100%;
    height: $item_height;
    margin-top: 20px;
    border: none;
    border-radius: 10px !important;

    &:focus {
      outline: none;
    }
  }

  .default-btn-go {
    background-color: var(--secondary-color) !important;
    cursor: not-allowed;
  }

  .active-btn-go {
    background-color: var(--primary-color);
  }

  .btn-loading {
    font-size: 16px;
    width: 30px;
    height: 30px;
  }

  input.error {
    caret-color: $error_color;
    border-color: $error_color !important;
  }

  .msg-error {
    font-size: 12px;
    color: $error_color;
    text-align: right;
    white-space: nowrap;
    margin-top: 2px;
  }

  .msg-api {
    font-size: 14px;
    line-height: 16.8px;
  }

  .api-loading {
    color: var(--primary-color);
    right: 0;
  }

  .api-not-found {
    color: #ff0000;
    font-weight: 400;
    font-size: 13px;
    line-height: 30px;
  }

  .login-issue-text {
    font-weight: 600;
    line-height: 30px;
    mix-blend-mode: darken;
    margin-top: 0.75rem;
    a {
      color: var(--primary-color);
    }
  }

  .tooltip-top-page {
    p {
      margin: unset;
    }
    p:first-child {
      margin-bottom: 10px;
    }
  }

  .span-icon:hover + .tooltip-top-page{
    z-index: 1;
    opacity: 1;
  }

  .span-icon svg {
    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and(max-width: 440px) {
  .rego-search-content {
    max-width: 358px !important;
    min-height: 426px !important;

    .api-not-found {
      line-height: 20px;
    }
  }
}

@media screen and(max-width: 768px) {
  .rego-search-content {
    padding: 40px 30px 18px !important;

    .login-issue-text {
      margin-top: 0.5rem !important;
    }
  }
}

@media screen and(min-width: 768px) {
  .rego-search-content {
    
    .span-icon svg {
      width: 15px !important;
      height: 15px !important;
    }
  }
}

.rego-search-content {
  font-family: "Avenir Next LT W05 Regular";
  width: 448px;
  min-height: 440px;
  padding: 40px 30px 10px;
  border-radius: 4px;
  text-align: left;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.1);

  .title-rego-search {
    .welcome-title {
      font-weight: 600;
      font-size: 36px;
      line-height: 40px;
      letter-spacing: -0.9px;
      color: var(--primary-color);
    }

    .welcome-content {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #6f6f6f;
    }
  }

  .input-login {
    background: #f3f4f6;
    border: 1px solid #ffffff;
    border-radius: 6px;
    max-width: 392px;
    height: 49.33px;
  }

  input.form-control {
    font-weight: 400;
  }
}
</style>
