<template>
  <div class="universal-login-content">
    <div class="universal-login-header">
      <div
        class="col-content d-flex justify-content-between align-items-center"
      >
        <img
          :src="logo.logoHeaderTopPage || '@/assets/images/logo_header.svg'"
          @click="logoHeader"
          class="cursor-pointer ml-14"
        />
        <div class="d-flex text-white contact">
          <a :href="`mailto:${contactDetails.email}`" class="d-flex">
            <img :src="contactDetails.mailIcon" class="cursor-pointer mr-12" />
            <p class="mb-0 fs-14">{{ contactDetails.email }}</p>
          </a>
          <a :href="`tel:${contactDetails.phoneNumber}`" class="d-flex">
            <img :src="contactDetails.phoneIcon" />
            <p class="mb-0 fs-14">{{ contactDetails.phoneNumber }}</p>
          </a>
        </div>
      </div>
    </div>
    <div class="universal-login-wrapper">
      <div class="universal-login-hero" :style="!isMobile ? '' : 'display: none;'">
        <img :src="heroImage" alt="" />
      </div>
      <div>
        <div class="universal-login-title">
          <h1>Login to AutoFlip</h1>
          <p>
            Enter your email to receive a link to login.
          </p>
        </div>
        <div class="universal-login-form">
          <div class="position-relative mb-2 mt-3">
            <label for="email">Email</label>
            <b-form-input
              class="input-login"
              type="email"
              v-model.trim="email"
              placeholder="Enter email"
              autocomplete="off"
              required
              @keyup.enter="submit"
              :class="{
                error: $v.email.$error,
                'shadow-none': true,
              }"
            />
            <p
              v-if="$v.email.$dirty && !$v.email.email"
              class="msg-error mb-0 d-block"
            >
              Invalid email format.
            </p>
            <p
              v-if="$v.email.$dirty && !$v.email.required"
              class="msg-error mb-0 d-block"
            >
              Please enter your email to proceed.
            </p>
          </div>
          <button
            @click="submit"
            :class="[
              activeBtnGo ? 'active-get-link-btn' : 'default-get-link-btn',
              'get-link-btn fs-16 text-white',
            ]"
            ref="searchButton"
          >
            <b class="font-weight-regular" v-if="!isLoadingApi">Get login link</b>
            <b-spinner v-else label="Loading..." class="btn-loading"></b-spinner>
          </button>
          <div class="trouble-login">
            <span>Having trouble logging in? <a :href="myCarLinks.troubleLogging" target="_blank">Click here</a></span>
          </div>
        </div>
      </div>
    </div>
    <smart-guided-camera></smart-guided-camera>
    <Footer></Footer>
  </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import Footer from "@/components/layout/Footer";
import SmartGuidedCamera from "@/components/top-page/SmartGuidedCamera";
import universalLoginService from "@/services/universal-login";
import { logError } from "@/helper/utils";
export default {
  name: "Index",
  data() {
    return {
      email: "",
      isMobile: true,
      isLoadingApi: false,
    };
  },
  computed: {
    ...mapState({
      contactDetails: (state) => state.commonVariables.contactDetails,
      autoflipLogo: (state) => state.commonVariables.autoflipLogo,
      logo: (state) => state.commonVariables.logo,
      myCarLinks: (state) => state.commonVariables.myCarLinks,
      heroImage: (state) => state.commonVariables.heroImage,
      locid: (state) => state.authenticateSeller.locid,
    }),
    activeBtnGo: function () {
      return !this.$v.email.$invalid;
    },
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 1025px)");
    },
  },
  methods: {
    logoHeader(e) {
      e.preventDefault();
      window.open(this.autoflipLogo, "_blank");
    },
    hideError() {
      // TODO
    },
    async submit() {
      this.$v.email.$touch();
      if (!this.$v.email.$invalid) {
        this.isLoadingApi = true;
        try {
          const response = await universalLoginService.getLoginLinks({
            email: this.email,
            locid: this.locid
          })
          if (response.status  === 200) {
            this.isLoadingApi = false;
            this.$store.dispatch(
              "setEmail",
              this.email
            );
            this.$store.dispatch(
              "setLocid",
              this.locid
            );
            this.$router.push({
              name: "login-success",
              query: this.$route.query,
            });
          }
        } catch(err) {
          this.isLoadingApi = false;
          logError(`Get login links error: ${err}`);
        }
      }
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  components: {
    SmartGuidedCamera,
    Footer,
  },
  mounted() {
    this.$store.dispatch("clearEmail");
    this.$store.dispatch("clearLocid");

    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
    this.isMobile = !this.mobileMediaQuery.matches;
  },
};
</script>
<style lang="scss">
$error_color: #E40000;
$item_height: 50px;
.universal-login-content {
  color: #404040;
  text-align: center;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  .universal-login-wrapper {
    font-family: "Avenir Next LT W05 Regular";
    background-color: #f9fafb;
    padding: 25px 16px;
    .universal-login-title {
      margin-bottom: 18px;
      h1 {
        font-family: "Avenir Next LT W05 Medium";
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        margin-bottom: 10px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #6f6f6f;
        max-width: 494px;
        margin: auto;
      }
    }
    .universal-login-form {
      max-width: 534px;
      padding: 10px 16px;
      text-align: left;
      border-radius: 20px;
      background: #ffffff;
      box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
        0px 2px 4px -2px rgba(0, 0, 0, 0.1);
      input.form-control {
        font-weight: 400;
      }
      
      .input-login {
        background: #f3f4f6;
        border: 1px solid #ffffff;
        border-radius: 6px;
        max-width: 502px;
        height: $item_height;
      }
      .get-link-btn {
        width: 100%;
        height: $item_height;
        margin-top: 16px;
        border: none;
        border-radius: 10px !important;
        &:focus {
          outline: none;
        }
      }
      .default-get-link-btn {
        background-color: var(--secondary-color) !important;
        cursor: not-allowed;
      }
      .active-get-link-btn {
        background-color: var(--primary-color);
      }
      .trouble-login {
        padding: 13px 32px;
        margin-top: 18px;
        color: #6f6f6f;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          font-weight: 600;
          color: #1F8252;
          text-decoration: underline;
        }
      }
    }
  }
  .universal-login-header {
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    img {
      width: 157px;
      height: 30px;
    }
    .contact {
      height: fit-content;
      a {
        color: #000;
        text-decoration: none;
      }
      p {
        display: none;
        margin-top: auto;
      }
      img {
        width: 25px !important;
        height: 25px !important;
        margin-right: 12px;
      }
    }
  }
  .msg-error {
    font-size: 14px;
    line-height: 24px;
    color: $error_color;
    text-align: left;
    margin-top: 2px;
    margin-left: 12px;
  }
}
@media screen and(min-width: 360px) {
  .universal-login-header {
    padding: 23px 0;
    .col-content {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .universal-login-form {
    margin: auto;
  }
}
@media screen and(min-width: 768px) {
  .universal-login-header {
    img {
      width: 210px !important;
      height: 40px !important;
      margin-left: 0;
    }
    .contact {
      a:last-child {
        margin-left: 37px;
      }
      p {
        display: block !important;
      }
    }
    .col-content {
      max-width: 1099px;
      margin: 0 auto;
    }
  }
  .universal-login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    padding: 50px 16px !important;

    .universal-login-hero img {
      width: 554px;
      height: 416.29px;
    }

    .universal-login-title {
      margin-bottom: 30px !important;
      h1 {
        font-size: 52px !important;
        line-height: 62px !important;
        margin-bottom: 18px !important;
      }
      p {
        font-size: 18px !important;
        line-height: 30px !important;
      }
    }
    .universal-login-form {
      padding: 25px 30px !important;
      .msg-error {
        font-size: 16px;
      }
    }
  }
}
@media screen and(min-width: 1200px) {
  .universal-login-header {
    padding: 18px 0;
    margin-left: 0;
    margin-right: 0;
    .contact {
      padding-bottom: 1px !important;
      a:last-child {
        margin-left: 49px;
      }
    }
    .col-content {
      width: 100%;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
}
@media (min-resolution: 125dpi) {
  .universal-login-header {
    padding: 18px 0;
  }
}
</style>