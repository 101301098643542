<template>
   <!-- Loading screen -->
  <div v-if="isLoadingScreen" class="loading-screen">
    <b-spinner label="Loading..." class="btn-loading"></b-spinner>
  </div>
  <div v-else-if="isUniversalLogin">
    <UniversalLogin />
  </div>
  <div v-else>
    <div class="top-page-content">
      <rego-search></rego-search>
      <smart-guided-camera></smart-guided-camera>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import topPageService from "@/services/top-page";
import {
  ACTION_AUTHENTICATE_SELLER,
  REGO_RE_LOOKUP,
  SET_LOCATION_ID,
  SAVE_ACTION_LOG,
  SET_GUIDED_CAMERA_SMS_URL,
} from "@/store/authenticate-seller/actions";
import { GET_NZ_VARIABLES } from "@/store/common-variables/actions";
import { SET_REGO_SEARCH_FLAG } from "@/store/info/actions";
import {
  getParamFromURL,
  isEmpty,
  imageObjectsFromUrls,
  logError,
} from "@/helper/utils";
import UniversalLogin from "@/views/universal-login/Index";
import RegoSearch from "@/components/top-page/RegoSearch";
import SmartGuidedCamera from "@/components/top-page/SmartGuidedCamera";
import Footer from "@/components/layout/Footer";
export default {
  name: "Index",
  data() {
    return {
      authenticateData: {
        appraisalId: "",
        locid: 1,
        generatedTime: "",
      },
      isUniversalLogin: true,
      authenticateSellerSuccess: false,
      isUseGuidedCamera: false,
    };
  },
  computed: {
    ...mapState({
      isLoadingScreen: (state) => state.common.isLoadingScreen,
      locid: (state) => state.authenticateSeller.locid,
    }),
  },
  components: {
    UniversalLogin,
    RegoSearch,
    SmartGuidedCamera,
    Footer,
  },
  methods: {
    async authenticateSeller() {
      try {
        const authenticateResult = await topPageService.authenticateSeller(
          this.authenticateData
        );
        if (
          authenticateResult.status === 201 &&
          authenticateResult.data.authenticated
        ) {
          authenticateResult.data.appraisalId =
            this.authenticateData.appraisalId;
          authenticateResult.data.locid = this.authenticateData.locid;
          const {
            imageUrls,
            newImages,
            deletedImages,
            labels,
            carInfo,
            rego,
            listed,
            readonlyMode,
            leadSource,
          } = authenticateResult.data;
          if (imageUrls && imageUrls.length > 0) {
            this.$store.dispatch(
              "setUploadedImages",
              imageObjectsFromUrls(imageUrls, true, deletedImages)
            );
            this.$store.dispatch("setIsUseGuidedUpload", false);
          }
          if (newImages && newImages.length > 0) {
            this.$store.dispatch("setUploadedImages", [
              ...this.uploadImages,
              ...imageObjectsFromUrls(newImages, false, deletedImages),
            ]);
          }
          this.$store.dispatch(
            ACTION_AUTHENTICATE_SELLER,
            authenticateResult.data
          );
          if (
            labels.some(
              (label) => label.toLowerCase() === "rego_lookup_not_done"
            ) &&
            (carInfo.location || this.authenticateData.locid === "2")
          ) {
            if (["TEMPREGO", ""].includes(rego)) {
              this.$store.dispatch(
                "updateIsShowRegoPopup",
                listed || readonlyMode ? false : true
              );
            } else {
              await this.handleRegoSearch(rego, carInfo.location, leadSource);
            }
          }
          const newQuery = this.$route.query;
          if (this.isUniversalLogin) {
            delete newQuery.appraisalId;
            delete newQuery.locid;
          }
          delete newQuery.universal_login;
          delete newQuery.generated_time;
          delete newQuery.guided_camera;
          this.authenticateSellerSuccess = true;
          // Check is have use guided camera sms
          const routeName = this.isUseGuidedCamera ? "photo" : "navigation";
          await this.$router.push({
            name: routeName,
            query: newQuery,
          });
          if (this.isUseGuidedCamera) this.$root.$emit("initImgUploadCamera");
        } else if (this.isUniversalLogin) {
          this.$store.dispatch(SAVE_ACTION_LOG, {
            appraisalId: this.authenticateData.appraisalId,
            url: window.location.href,
          });
        }
      } catch (err) {
        if (err.response && err.response.status === 422) {
          const errors = err.response.data.errors;
          Object.keys(errors).forEach((field) => {
            this.$toast.clear();
            this.$toast.error(
              `${err.response.data.message}: ${errors[field][0]}`
            );
          });
        } else if (err.response) {
          this.$toast.clear();
          this.$toast.error(err.response.data.message);
        } else {
          logError(`Authenticate seller error: ${err}`);
        }
      }
    },
    async handleRegoSearch(regoNumber, state, leadSource) {
      try {
        this.$store.dispatch(SET_REGO_SEARCH_FLAG, true);
        const seachResult = await topPageService.searchRego({
          regoNumber,
          state,
          leadSource,
          locid: this.authenticateData.locid,
        });
        if (seachResult.status === 201) {
          this.$store.dispatch(REGO_RE_LOOKUP, seachResult.data);
        }
      } catch (error) {
        if (error.response.status !== 404) {
          logError(`Rego search error: ${error}`);
        }
      }
    },
    removeUtmTags(url) {
      let urlObj = new URL(url);
      let params = new URLSearchParams(urlObj.search);
      let keysToDelete = [];

      // Collect keys to delete
      params.forEach((value, key) => {
        if (key.startsWith('utm_')) {
          keysToDelete.push(key);
        }
      });

      // Delete the collected keys
      keysToDelete.forEach(key => {
        params.delete(key);
      });

      // Add the new parameter
      params.set('guided_camera', 'true');

      // Reconstruct the URL without utm parameters
      urlObj.search = params.toString();
      return urlObj.toString();
    },
  },
  async mounted() {
    const guidedCameraSMSUrl = this.removeUtmTags(window.location.href);
    if (guidedCameraSMSUrl) this.$store.dispatch(SET_GUIDED_CAMERA_SMS_URL, guidedCameraSMSUrl);
    this.$store.dispatch("setLoadingScreen", true);
    const appraisalId = getParamFromURL(window.location.href, "appraisalId") || "";
    let locid;
    // Check is production MyCar
    if (window.location.hostname.includes('mycar')) {
      locid = window.location.hostname.includes('nz') ? 2 : ""
    } else {
      locid = getParamFromURL(window.location.href, "locid") || this.locid || "";
    }
    const universalLogin = getParamFromURL(window.location.href, "universal_login") || "";
    const generatedTime = getParamFromURL(window.location.href, "generated_time") || "";

    this.$store.dispatch(SET_LOCATION_ID, locid);
    this.authenticateData.appraisalId = appraisalId;
    this.authenticateData.locid = locid;
    this.authenticateData.generatedTime = generatedTime;
    this.isUniversalLogin = universalLogin || !appraisalId;
    this.isUseGuidedCamera = getParamFromURL(window.location.href, "guided_camera") || false;
    window.localStorage.setItem('universal-qs', JSON.stringify(Object.assign({}, this.$route.query)));

    if (appraisalId && this.isUniversalLogin) {
      await this.authenticateSeller();
    }
    if (!isEmpty(this.$route.query) && !this.authenticateSellerSuccess) {
      const currentQuery = {...this.$route.query};
      if (this.isUniversalLogin) {
        delete currentQuery.appraisalId;
        delete currentQuery.locid;
      }
      delete currentQuery.universal_login;
      delete currentQuery.generated_time;
      delete currentQuery.guided_camera;

      const currentRoute = this.$route.fullPath;
      const targetRoute = this.$router.resolve({ path: '/', query: currentQuery }).route.fullPath;
      if (currentRoute !== targetRoute ) {
        this.$router.replace({ query: currentQuery });
      }
    }

    this.$store.dispatch(GET_NZ_VARIABLES, {
      locid,
      uxeId: parseInt(locid) === 2 ? "MycarNZ" : "Mycar",
      appraisalId,
    });
  },
};
</script>

<style lang="scss">
.top-page-content {
  color: #404040;
  text-align: center;
  background-color: #ffffff;
}
</style>
