<template>
  <div>
    <div class="rego-wrapper">
      <div class="rego-content">
        <div class="top-content-rego">
          <div class="col-content d-flex justify-content-between align-items-center">
            <img
              :src="logo.logoHeaderTopPage || '@/assets/images/logo_header.svg'"
              @click="logoHeader"
              class="cursor-pointer ml-14"
            />
            <div class="d-flex text-white contact">
              <a :href="`mailto:${contactDetails.email}`" class="d-flex">
                <img
                  :src="contactDetails.mailIcon"
                  class="cursor-pointer mr-12"
                />
                <p class="mb-0 fs-14">{{ contactDetails.email }}</p>
              </a>
              <a :href="`tel:${contactDetails.phoneNumber}`" class="d-flex">
                <img :src="contactDetails.phoneIcon" />
                <p class="mb-0 fs-14">{{ contactDetails.phoneNumber }}</p>
              </a>
            </div>
          </div>
        </div>
        <div class="container-form">
          <div class="flex-form">
            <div class="bottom-content-rego text-white text-left" :style="!isMobile ? '' : 'display: none;'">
              <img :src="heroImage" alt="" />
            </div>
            <div class="rego-desktop" :style="!isMobile ? '' : 'margin-top: unset !important;'">
              <RegoSearchForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegoSearchForm from "./RegoSearchForm.vue";
import { mapState } from "vuex";

export default {
  components: { RegoSearchForm },
  name: "RegoSearch",
  data() {
    return {
      isMobile: true,
    }
  },
  methods: {
    logoHeader(e) {
      e.preventDefault();
      window.open(this.autoflipLogo, "_blank")
    },
  },
  computed: {
    ...mapState({
      contactDetails: (state) => state.commonVariables.contactDetails,
      autoflipLogo: (state) => state.commonVariables.autoflipLogo,
      logo: (state) => state.commonVariables.logo,
      heroImage: (state) => state.commonVariables.heroImage,
    }),
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 1025px)");
    },
  },
  mounted() {
    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
    this.$store.dispatch('setOnEdit', false);
  },
};
</script>

<style lang="scss">
.rego-wrapper {
  .rego-content {
    width: 100%;
    height: 100%;

    .top-content-rego {
      display: flex;
      flex-wrap: wrap;
      background: #ffffff;

      img {
        width: 157px;
        height: 30px;
      }

      .contact {
        height: fit-content;

        a {
          color: #000;
          text-decoration: none;
        }

        p {
          display: none;
          margin-top: auto;
        }

        img {
          width: 25px !important;
          height: 25px !important;
          margin-right: 12px;
        }
      }
    }

    .bottom-content-rego {
      padding-left: 30px;
      padding-right: 33px;
      padding-top: 20px;
      bottom: 37px;

      .desc {
        color: #efefef;
        font-size: 13px;
        line-height: 15px;
        word-spacing: 0.2px;
        margin-top: 1px;
        display: none;
      }
    }
  }
}

@media screen and(min-width: 360px) {
  .top-content-rego {
    padding: 23px 0;

    .col-content {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .container-form {
    .flex-form {
      background-color: #f9fafb !important;
      padding-top: 20px;
      padding-bottom: 30px;
      .bottom-content-rego {
        text-align: center !important;
      }

      .bottom-content-rego img {
        width: 358px;
        height: 269.01px;
      }
    }
  }

  .rego-search {
    padding: 1rem 0 !important;
    background-color: #f9fafb !important;
  }
}

@media screen and(min-width: 768px) {
  .rego-wrapper {
    max-height: unset !important;

    .top-content-rego {
      img {
        width: 210px !important;
        height: 40px !important;
        margin-left: 0;
      }

      .contact {
        a:last-child {
          margin-left: 37px;
        }
        p {
          display: block !important;
        }
      }
    }

    .col-content {
      max-width: 1099px;
      margin: 0 auto;
    }

    .bottom-content-rego {
      padding: 0 !important;

      .desc {
        display: block !important;
        font-size: 20px !important;
        line-height: 24px !important;
        margin-top: 6px !important;
      }
    }

    .container-form {
      border-radius: 4px;
      display: flex;
      justify-content: center;
      background-color: #f9fafb;
      height: -webkit-fill-available;
      padding: unset;

      .flex-form {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        .bottom-content-rego img {
          width: 554px;
          height: 416.29px;
        }

        .copy-text {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }
  }
}

@media screen and(min-width: 1025px) {
  .rego-wrapper {
    .container-form {
      padding: 30px 30px;

      .flex-form {
        justify-content: space-between !important;
      }
    }

    .rego-desktop {
      padding: 40px 0 40px 0 !important;
    }
  }
}

@media screen and(min-width: 1200px) {
  .rego-content {
    .top-content-rego {
      padding: 18px 0;
      margin-left: 0;
      margin-right: 0;

      .contact {
        padding-bottom: 1px !important;
        a:last-child {
          margin-left: 49px;
        }
      }
  
      .col-content {
        width: 100%;
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
  
    .flex-form {
      width: 1148px !important;
      padding: 0 !important;
  
      .copy-text {
        font-size: 62px !important;
        line-height: 75px !important;
      }
    }
  }
}

@media (min-resolution: 125dpi) {
  .container-form {
    height: unset !important;
  }
  .top-content-rego {
    padding: 18px 0;
  }
}
</style>